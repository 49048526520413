<template>
  <v-container fluid class="mt-n6">
    <v-row>
      <v-col md="4" lg="3" class="hidden-sm-and-down ml-n3">
        <!-- DEKSTOP -->
        <v-card
          v-if="checkAllData"
          class="cardHeight"
          elevation="2"
          width="100%"
          style="overflow-hidden"
        >
          <v-toolbar color="#df6464" dark>
            <v-toolbar-title
              >{{ language == "en" ? "All Inbox Department" : "全てのメール" }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              @click="Refresh()"
              icon
              color="white"
              :to="type == 'Correction' ? '/AllInboxDepartment' : '/AllInboxDepartment'"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-text-field
            v-model="search"
            dense
            filled
            solo
            clearable
            rounded
            @click:clear="search = ''"
            color="#df6464"
            :label="language == 'en' ? 'Search' : '検索'"
            prepend-inner-icon="mdi-magnify"
            class="mr-3 ml-3 mb-n5 mt-2"
          ></v-text-field>
          <v-checkbox
            class="mr-3 ml-3 mb-n5 mt-3"
            style="float: right"
            v-model="showFilter"
            label="Show Filter"
          ></v-checkbox>
          <div v-show="showFilter">
            <v-autocomplete
              v-model="choseDept"
              :items="selectDepartment"
              outlined
              dense
              clearable
              rounded
              placeholder="Department"
              class="mr-3 ml-3 mb-n5 mt-2"
              @change="filterbySection(choseDept)"
            >
              <!-- @change="filterDepartment(getDept)" -->
            </v-autocomplete>
            <v-autocomplete
              v-model="choseSection"
              :items="listOfSection"
              outlined
              dense
              clearable
              rounded
              placeholder="Section"
              class="mr-3 ml-3 mb-n5 mt-2"
            >
              <!-- @change="filterDepartment(getDept)" -->
            </v-autocomplete>
            <!-- CheckBox -->
            <!-- <v-checkbox v-model="checkAll" label="Check All"></v-checkbox> -->

            <v-row no-gutters>
              <v-col cols="6" class="pa-0">
                <div class="pa-2">
                  <div class="font-weight-bold mb-1">送信者</div>
                  <v-checkbox
                    dense
                    v-model="sendingHRD"
                    label="HRD"
                    class="pa-0"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="sendingJAPAN"
                    label="JAPAN"
                    class="pa-0"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="6" class="pa-0">
                <div class="pa-2">
                  <div class="font-weight-bold mb-1">受信者</div>
                  <v-checkbox
                    dense
                    v-model="recievedHRD"
                    label="HRD"
                    class="pa-0"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="recievedJAPAN"
                    label="JAPAN"
                    class="pa-0"
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>

            <v-row class="mr-3 ml-3 mb-n5">
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value-sync="date1"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      label="Date From:"
                      prepend-inner-icon="mdi-calendar"
                      v-model="date1"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date1"
                    :show-current="false"
                    scrollable
                    no-title
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value-sync="date2"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      label="Date To:"
                      prepend-inner-icon="mdi-calendar"
                      v-model="date2"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    :show-current="false"
                    scrollable
                    no-title
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <v-row class="mr-3 ml-3 mb-n5"
            ><v-col>
              <v-btn block dense outlined color="primary" @click="searchInboxDept(true)">
                <v-icon left> mdi-magnify </v-icon>
                search
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mr-3 ml-3 mb-n1 mt-2">
            <v-col>
              <h3 style="float: right">Total:{{ count }}</h3>
            </v-col>
          </v-row>
          <v-divider class="mb-1"></v-divider>
          <v-list
            :height="!showFilter ? '580px' : '260px'"
            row
            wrap
            three-line
            class="listCard"
            v-if="items.length != 0"
          >
            <template v-for="(item, value) in items">
              <v-list-item
                :key="value.ConfirmationID"
                @click="hideMessage(item.ConfirmationID)"
                tag="span"
                :to="{
                  name: 'AllInboxDepartmentReply',
                  params: { id: item.ConfirmationID },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title :style="changeFont(item.SeenBy).fonts"
                    ><img
                      class="mr-1"
                      v-if="changeFont(item.SeenBy).imgBoolean"
                      src="../../assets/blueCircle.png"
                      alt=""
                      style="width: 13px; height: 11px"
                    />{{ item.CustomerCode }} - {{ item.Subject }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="contentAlign ml-3">{{
                    item.MessageContent
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="ml-1">
                  {{ getTime(item.DateCreated) }}
                  <br />
                  <span v-html="item.ClientDate"></span>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider :key="value.ConfirmationID"></v-divider>
            </template>
            <v-card v-intersect="infiniteScrolling"></v-card>

            <transition name="fade">
              <div class="loading" v-show="loading">
                <span><v-icon>mdi-loading mdi-spin</v-icon></span> Loading
              </div>
            </transition>
          </v-list>
          <div v-else class="noRecords" style="width: 100%">
            <v-img src="../../assets/inbox.png" width="65" style="margin: auto"> </v-img>
            <b style="font-size: 17px; color: silver">{{
              language == "en" ? "No Message Found" : "メッセージが見つかりません"
            }}</b>
          </div>
          <div v-if="items.length != 0">
            <div
              v-if="items.length === 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55vh;
              "
            >
              <b style="font-size: 20px; color: red; margin: auto">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </div>
        </v-card>
        <!-- LOADING SKELETON -->
        <v-skeleton-loader
          v-else
          class="cardHeight"
          elevation="2"
          type="card-avatar, article,article,article,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>

      <!-- FULL WIDTH -->
      <v-col v-bind:class="rowInbox">
        <v-card elevation="0" v-if="checkAllData">
          <v-toolbar color="#df6464" dark width="100%" class="d-none d-md-flex">
            <v-toolbar-title>受信トレイ</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-list v-if="items.length != 0" three-line class="listCardMobile" v-scrollbar>
            <template v-for="(item, value) in items">
              <v-list-item
                :key="value.ConfirmationID"
                @click="hideInbox(item.ConfirmationID)"
                tag="span"
                :to="{
                  name: 'AllInboxDepartmentReply',
                  params: { id: item.ConfirmationID },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title :style="changeFont(item.SeenBy).fonts"
                    ><img
                      class="mr-1"
                      v-if="changeFont(item.SeenBy).imgBoolean"
                      src="../../assets/blueCircle.png"
                      alt=""
                      style="width: 13px; height: 11px"
                    />{{ item.CustomerCode }} - {{ item.Subject }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="contentAlign ml-3">{{
                    item.MessageContent
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="ml-1">
                  <span v-html="item.ClientDate"></span>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider :key="value.ConfirmationID"></v-divider>
            </template>
          </v-list>
          <div v-else class="noRecords" style="width: 100%">
            <v-img src="../../assets/inbox.png" width="65" style="margin: auto"> </v-img>
            <b style="font-size: 17px; color: silver; margin: auto">{{
              language == "en" ? "No Message Found" : "メッセージが見つかりません"
            }}</b>
          </div>
          <div v-if="items.length != 0">
            <div
              v-if="items.length === 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55vh;
              "
            >
              <b style="font-size: 20px; color: red; margin: auto">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </div>
        </v-card>
        <!-- LOADING SKELETON -->
        <v-skeleton-loader
          v-else
          class="cardHeight"
          elevation="2"
          type="card-avatar, article,article,article,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>

      <!-- REPLY -->
      <v-col class="overflow-y-auto" md="8" lg="9" v-bind:class="rowReply">
        <div class="text-center mt-16" v-if="!$route.params.id">
          <b style="font-size: 23px; color: silver">{{
            language == "en" ? "No Message Selected" : "メッセージが選択されていません"
          }}</b
          ><br />
          <v-img src="../../assets/empty-email.png" width="100" style="margin: auto">
          </v-img>
        </div>
        <v-btn
          color="error"
          @click="showInbox"
          to="/received"
          class="hidden-md-and-up ml-5"
          >Back</v-btn
        >

        <transition name="fade">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import keys from "../../functions/alert";
import axios from "axios";
import store from "@/store";
import moment from "moment";
import confirmationTable from "../../functions/httpRequest/ExternalConnection/ConfirmationTable";
import getDepartment from "../../functions/httpRequest/ExternalConnection/UsersTable";
import getAllInboxDept from "../../functions/httpRequest/ExternalConnection/ReplyTable";

export default {
  data() {
    return {
      // checkAll: true,
      showFilter: false,
      sendingHRD: false,
      recievedHRD: false,
      recievedJAPAN: false,
      sendingJAPAN: false,

      loading: false,
      page: 10,
      choseDept: "",
      choseSection: "",
      getDept: "",
      listOfSection: [],
      selectDepartment: [],
      search: "",
      socket: "",
      show: true,
      checkAllData: false,
      rowInbox: "hidden-md-and-up",
      rowReply: "hidden-sm-and-down",
      userID: "",
      name: "",
      routerPath: "",
      type: store.state.userinfo.Type,
      items: [],
      date1: moment().subtract(1, "month").format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      count: "",
    };
  },
  mounted() {
    this.recievedHRD = true;
    this.recievedJAPAN = true;
    this.sendingJAPAN = true;
    this.sendingHRD = true;
  },
  created() {
    this.dateCount();
    this.getDepartments();
    // GET LOAD DATA FUNCTION
    this.loadData();
    // this.infiniteScrolling();
    // CHECK USER TYPE
    if (store.state.userinfo.EmployeeNumber) {
      this.userID = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.userID = store.state.userinfo.EmployeeCode;
    }
    // GET SOCKET IO
    this.socket = this.$io;
    // GET REALTIME MESSAGE IN INBOX
    this.socket.on("recievedMessage", (data) => {
      this.items.push({
        ConfirmationID: data.ConfirmationID,
        DateCreated: data.DateCreated,
        From: data.From,
        InquiryItem: data.InquiryItem,
        InquiryType: data.InquiryType,
        MessageContent: data.MessageContent,
        SenderID: data.SenderID,
        Status: data.Status,
        Subject: data.Subject,
        To: data.To,
      });
    });
  },
  computed: {
    // LANGUAGE LOCALE
    language() {
      return this.$i18n.locale;
    },
    //CHECK FOR ROUTE PATH
    checkRoutes() {
      if (this.routerPath == "/received/reply") return "";
      else return "received/reply";
    },
    //*****************************//
    //  FILTER/SEARCH RECORD DESC  //
    //*****************************//

    // filteredAndSorted() {
    //   function compare(a, b) {
    //     if (a.Subject < b.Subject) return -1;
    //     if (a.Subject > b.Subject) return 1;
    //     return 0;
    //   }
    //   const newItem = this.items
    //     .filter((res) => {
    //       return (
    //         res.Subject.toLowerCase().includes(this.search.toLowerCase())
    //       );
    //     })
    //     .sort(compare);
    //   newItem.sort((a, b) => {
    //     const c = new Date(a.DateCreated);
    //     const d = new Date(b.DateCreated);
    //     return d - c;
    //   });
    //   return newItem;
    // },
  },
  watch: {
    // WATCH PATH CHANGE
    $route(to) {
      this.routerPath = to.path;
    },
  },
  methods: {
    filterSendingJAPAN() {
      this.page++;
      axios
        .get(
          `${keys.auth.BaseUrl}/getSendingJAPAN/${this.page}/${this.date1}/${
            this.date2
          }/${this.search ? this.search : "null"}`,
          keys.auth.apiKey
        )
        .then((res) => {
          // let displaySendingJAPAN = res.data.filter((rec) => {
          //   if (rec.Type == 1) return rec;
          // });
          this.items = Object.assign(res.data);
          for (let x in this.items) {
            const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
            const dateFormat = moment().format("YYYY-MM-DD");
            const momentDate = moment(dateFormat);
            const dateDifference = momentDate.diff(userDate, "days");

            // Remove <br> tag and split MessageContent
            this.items[x].MessageContent = this.items[x].MessageContent.split(
              "<br>"
            ).join("\n");

            if (dateDifference > 1) {
              this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                "YYYY-MM-DD"
              );
            } else {
              this.items[x].ClientDate = moment(this.items[x].DateCreated)
                .startOf()
                .fromNow();
            }
          }
          this.count = this.items.length;
        });

      // getSendingJAPANConfirmation
    },
    filterSendingHRD() {
      this.page++;
      axios
        .get(
          `${keys.auth.BaseUrl}/getSendingHRD/${this.page}/${this.date1}/${this.date2}/${
            this.search ? this.search : "null"
          }/`,
          keys.auth.apiKey
        )
        .then((res) => {
          console.log(res.data);
          this.items = Object.assign(res.data);
          for (let x in this.items) {
            const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
            const dateFormat = moment().format("YYYY-MM-DD");
            const momentDate = moment(dateFormat);
            const dateDifference = momentDate.diff(userDate, "days");

            // Remove <br> tag and split MessageContent
            this.items[x].MessageContent = this.items[x].MessageContent.split(
              "<br>"
            ).join("\n");

            if (dateDifference > 1) {
              this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                "YYYY-MM-DD"
              );
            } else {
              this.items[x].ClientDate = moment(this.items[x].DateCreated)
                .startOf()
                .fromNow();
            }
          }
          // this.count = this.items.length;
        });
      axios
        .get(
          `${keys.auth.BaseUrl}/getConfirmationDate/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.count = res.data[0].DataLength;
          // console.log(res.data)
        });
    },

    getTime: (time) => moment(time).format("hh:mm A"),
    Refresh() {
      this.sendingHRD = true;
      this.sendingJAPAN = true;
      this.recievedJAPAN = true;
      this.recievedHRD = true;
      this.loadData();
      this.search = "";
      this.choseDept = "";
    },
    searchInboxDept(val) {
      if (this.search) {
        this.searchInboxWithOutDept();
        return false;
      }

      if (this.choseDept) {
        this.filterDepartment();
      } else if (this.recievedJAPAN == val && !this.search) {
        this.filterSendingHRD();
      } else if (this.recievedHRD == val && !this.search) {
        this.filterSendingJAPAN();
      } else if (this.sendingHRD == val && !this.search) {
        this.filterSendingHRD();
      } else if (this.sendingJAPAN == val && !this.search) {
        this.filterSendingJAPAN();
      } else if (this.search && this.choseDept) {
        this.searchInbox();
      } else if (this.search) {
        this.searchInboxWithOutDept();
      } else {
        alert("Please Input the fields");
      }
    },
    searchInboxWithOutDept() {
      this.page++;

      axios
        .get(
          `${keys.auth.BaseUrl}/getSearchCodeWithOutDept/${this.page}/${this.search}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.items = res.data.filter((rec) => {
            if (this.search == rec.CustomerCode) {
              rec.To = rec.To.split(",");
              return rec.CustomerCode;
              // console.log(rec,"HOTDOG")
            } else if (this.search == rec.Subject) {
              rec.To = rec.To.split(",");
              return rec.Subject;
              // console.log(rec.Subject,"CHICKEND")
            } else {
              rec.To = rec.To.split(",");
              return rec;
            }
          });
          this.count = this.items.length;

          // if(!this.search){
          //   this.loadData();
          //   this.DeptCount();

          // }
          // console.log(searcFilter);
        });
    },

    searchInbox() {
      this.page++;

      axios
        .get(
          `${keys.auth.BaseUrl}/getSearchCode/${this.page}/${this.search}/${this.choseDept}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.items = res.data.filter((rec) => {
            if (this.search == rec.CustomerCode) {
              rec.To = rec.To.split(",");
              return rec.CustomerCode;
              // console.log(rec,"HOTDOG")
            } else if (this.search == rec.Subject) {
              rec.To = rec.To.split(",");
              return rec.Subject;
              // console.log(rec.Subject,"CHICKEND")
            } else {
              rec.To = rec.To.split(",");
              return rec;
            }
          });
          for (let x in this.items) {
            const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
            const dateFormat = moment().format("YYYY-MM-DD");
            const momentDate = moment(dateFormat);
            const dateDifference = momentDate.diff(userDate, "days");

            // Remove <br> tag and split MessageContent
            this.items[x].MessageContent = this.items[x].MessageContent.split(
              "<br>"
            ).join("\n");

            if (dateDifference > 1) {
              this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                "YYYY-MM-DD"
              );
            } else {
              this.items[x].ClientDate = moment(this.items[x].DateCreated)
                .startOf()
                .fromNow();
            }
          }
          this.count = this.items.length;

          // if(!this.search){
          //   this.loadData();
          //   this.DeptCount();

          // }
          // console.log(searcFilter);
        });
    },
    filterByDate() {
      this.page++;
      // let a = this.page++;
      // let skipTotal = a
      axios
        .get(
          `${keys.auth.BaseUrl}/getConfirmation/${this.page}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          // console.log(res.data,"LOAD DATA")
          this.items = Object.assign(res.data);
          // this.items = res.data.filter((rec) => {
          //   rec.To = rec.To.split(",");
          //   return rec;
          //   // return rec.To == this.name
          // });
          //  console.log(this.items)
          for (let x in this.items) {
            const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
            const dateFormat = moment().format("YYYY-MM-DD");
            const momentDate = moment(dateFormat);
            const dateDifference = momentDate.diff(userDate, "days");

            // Remove <br> tag and split MessageContent
            this.items[x].MessageContent = this.items[x].MessageContent.split(
              "<br>"
            ).join("\n");

            if (dateDifference > 1) {
              this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                "YYYY-MM-DD"
              );
            } else {
              this.items[x].ClientDate = moment(this.items[x].DateCreated)
                .startOf()
                .fromNow();
            }
          }
          this.checkAllData = true;
        });
      axios
        .get(
          `${keys.auth.BaseUrl}/getConfirmationDate/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.count = res.data[0].DataLength;
          // console.log(res.data)
        });
      this.filterDepartment();
      this.searchInbox();
      //  this.searchControlCode();
    },
    dateCount() {
      axios
        .get(
          `${keys.auth.BaseUrl}/getConfirmationDate/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.count = res.data[0].DataLength;
          // console.log(res.data)
        });
    },
    //*******************************//
    //  CHANGE FONT OF SEEN MESSAGE  //
    //*******************************//

    changeFont(val) {
      // IF MESSAGE CLICK
      if (val) {
        const subject = val.split(",");
        const news = subject.filter((rec) => rec == this.userID);

        if (news.length > 0) {
          return {
            fonts: "margin-left: 10px",
            imgBoolean: false,
          };
        }
      }
      // IF NOT SEEN YET
      return {
        fonts: "font-weight:bold;font-size: 15px; margin-left: -1px",
        imgBoolean: true,
      };
    },
    //*****************************//
    //    GET CONFIRMATION API     //
    //*****************************//

    getDepartments() {
      getDepartment.GetDepartment().then((res) => {
        let getDepartment = res.data.map((rec) => {
          return rec.Department;
        });
        this.selectDepartment = Object.assign(getDepartment);
        // console.log(res.data);
      });
    },
    filterbySection(val) {
      getDepartment.GetDepartment().then((res) => {
        let filterSect = res.data.filter((rec) => {
          if (val == rec.Department) {
            return rec.Section;
          }
        });
        let mapSect = filterSect.map((rec) => {
          return rec.Section;
        });
        this.listOfSection = Object.assign(mapSect);
      });
    },
    loadData() {
      this.page++;
      // let a = this.page++;
      // let skipTotal = a
      axios
        .get(
          `${keys.auth.BaseUrl}/getConfirmation/${this.page}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          console.log(res.data, "LOAD DATA");
          this.items = Object.assign(res.data);
          // this.items = res.data.filter((rec) => {
          //   rec.To = rec.To.split(",");
          //   return rec;
          //   // return rec.To == this.name
          // });
          //  console.log(this.items)
          for (let x in this.items) {
            const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
            const dateFormat = moment().format("YYYY-MM-DD");
            const momentDate = moment(dateFormat);
            const dateDifference = momentDate.diff(userDate, "days");

            // Remove <br> tag and split MessageContent
            this.items[x].MessageContent = this.items[x].MessageContent.split(
              "<br>"
            ).join("\n");

            if (dateDifference > 1) {
              this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                "YYYY-MM-DD"
              );
            } else {
              this.items[x].ClientDate = moment(this.items[x].DateCreated)
                .startOf()
                .fromNow();
            }
          }
          this.checkAllData = true;
        });
      axios
        .get(
          `${keys.auth.BaseUrl}/getConfirmationDate/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.count = res.data[0].DataLength;
          // console.log(res.data)
        });
    },
    DeptCount() {
      axios
        .get(
          `${keys.auth.BaseUrl}/getCountDept/${this.choseDept}/${this.date1}/${
            this.date2
          }/${this.search ? this.search : "null"}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.count = res.data[0].DataLength;
          // console.log(res.data)
        });
    },
    filterDepartment() {
      this.page++;
      let a = this.page++;
      let skipTotal = a + 5;
      let data = {
        skipTotal: skipTotal,
        choseDept: this.choseDept,
        choseSection: this.choseSection ? this.choseSection : "null",
        date1: this.date1,
        date2: this.date2,
        search: this.search ? this.search : "null",
      };

      getAllInboxDept.getAllInboxDepartment(data).then((res) => {
        console.log(res.data);
        let filterDept = res.data.filter((rec) => {
          if (this.choseDept == rec.Department) {
            return rec.Department;
          }
        });
        this.items = Object.assign(filterDept);
        for (let x in this.items) {
          const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
          const dateFormat = moment().format("YYYY-MM-DD");
          const momentDate = moment(dateFormat);
          const dateDifference = momentDate.diff(userDate, "days");

          // Remove <br> tag and split MessageContent
          this.items[x].MessageContent = this.items[x].MessageContent.split("<br>").join(
            "\n"
          );

          if (dateDifference > 1) {
            this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
              "YYYY-MM-DD"
            );
          } else {
            this.items[x].ClientDate = moment(this.items[x].DateCreated)
              .startOf()
              .fromNow();
          }
        }
        this.DeptCount();

        // console.log(this.items);
        if (!this.choseDept) {
          this.loadData();
        }
      });
    },
    async infiniteScrolling() {
      // if (this.search) {
      //   this.searchInboxWithOutDept();
      //   return false;
      // }
      if (this.choseDept) {
        this.filterDepartment();
        return false;
      }
      // this.filterSendingHRD();

      // this.filterSendingJAPAN();
      if (this.recievedJAPAN == true && !this.search) {
        this.filterSendingHRD();
        return false;
      }
      if (this.recievedHRD == true && !this.search) {
        this.filterSendingJAPAN();
        return false;
      }
      if (this.sendingHRD == true && !this.search) {
        this.filterSendingHRD();
        return false;
      }
      if (this.sendingJAPAN == true && !this.search) {
        this.filterSendingJAPAN();
        return false;
      }

      if (this.search && this.choseDept) {
        this.searchInbox();
        return false;
      }

      this.loading = true;
      setTimeout(async () => {
        if (this.choseDept) {
          // this.loading = true;
          this.page++;
          let a = this.page++;
          let skipTotal = a + 5;
          let data = {
            skipTotal: skipTotal,
            choseDept: this.choseDept,
            choseSection: this.choseSection ? this.choseSection : "null",
            date1: this.date1,
            date2: this.date2,
            search: this.search ? this.search : "null",
          };

          await getAllInboxDept
            .getAllInboxDepartment(data)
            .then((res) => {
              console.log(res.data);
              if (res.data.length > 1) {
                let filterDept = res.data.filter((rec) => {
                  if (this.choseDept == rec.Department) {
                    return rec.Department;
                  }
                });
                this.items = Object.assign(filterDept);
                if (!this.choseDept && !this.search) {
                  this.loadData();
                }
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });
          return false;
        }
        if (!this.choseDept && !this.search) {
          this.loadData();
        }

        this.loading = false;
      }, 2000);
    },

    //******************************************//
    //    UPDATE SEEN IN DATABASE FUNCTION     //
    //*****************************************//

    hideInbox(id) {
      // UPDATE IN DATABASE
      confirmationTable.GetSpecificConfirmation(id).then(async (res) => {
        let addData = null;
        let cntData = 0;
        if (res.data[0].SeenBy) {
          const data = res.data[0].SeenBy.split(",");
          for (let x in data) if (data[x] == this.userID) cntData++;
          if (cntData == 0) addData = res.data[0].SeenBy + this.userID + ",";
          else addData = res.data[0].SeenBy;
        } else addData = this.userID + ",";

        const dataToUpdate = {
          ConfirmationID: id,
          SeenBy: addData,
        };
        await confirmationTable.Update(dataToUpdate);
      });
      // INSERT ID IN VUEX
      for (let x in this.items)
        if (this.items[x].ConfirmationID == id) this.items[x].SeenBy = this.userID;
      this.ReplyID(id);
      this.rowInbox = "hidden-sm-and-down";
      this.rowReply = "hidden-md-and-up";
      this.show = false;
    },
    //*****************************//
    //      IF MOBILE SIZE        //
    //*****************************//

    showInbox() {
      this.rowInbox = "hidden-md-and-up";
      this.rowReply = "hidden-sm-and-down";
    },
    //*******************************//
    //     IF MOBILE SIZE UPDATE     //
    //*******************************//

    hideMessage(conID) {
      confirmationTable.GetSpecificConfirmation(conID).then((res) => {
        let addData = null;
        let cntData = 0;
        if (res.data[0].SeenBy) {
          const data = res.data[0].SeenBy.split(",");
          for (let x in data) if (data[x] == this.userID) cntData++;
          if (cntData == 0) addData = res.data[0].SeenBy + this.userID + ",";
          else addData = res.data[0].SeenBy;
        } else addData = this.userID + ",";

        const dataToUpdate = {
          ConfirmationID: conID,
          SeenBy: addData,
        };

        confirmationTable.Update(dataToUpdate);
      });
      for (let x in this.items)
        if (this.items[x].ConfirmationID == conID) this.items[x].SeenBy = this.userID;
      this.ReplyID(conID);
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.listCard {
  overflow: auto;
  max-height: calc(79vh - 70px);
}
.listCardMobile {
  max-height: calc(103vh - 128px);
}
.cardHeight {
  max-height: calc(90vh - 124px);
  min-height: calc(90vh - 56px);
}
.noRecords {
  color: grey;
  text-align: center;
  width: 100%;
  padding-top: 25vh;
}
.contentAlign {
  max-height: 70px;
  p {
    color: silver;
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 1;
  background: #df6464;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
