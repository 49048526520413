import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //****************************************//
  //     GET ALL USER  IN USERS TABLE      //
  //***************************************//
  GetDepartment(){
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getdepartment`, keys.auth.apiKey)
    );
  },

  GetAllUser() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/users`, keys.auth.apiKey)
    );
  },
  //****************************************//
  //  GET SPECIFIC REQUEST IN USERS TABLE  //
  //***************************************//

  GetSpecificUser(id) {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/users/${id}`, keys.auth.apiKey)
    );
  },

  //****************************************//
  //  GET SPECIFIC EMAIL IN USERS TABLE    //
  //***************************************//
  getSpecificEmail(email) {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getEmail/${email}`, keys.auth.apiKey)
    );
  },

  //*******************************//
  //  POST REQUEST IN USERS TABLE  //
  //*******************************//

  Post(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/register`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //*******************************//
  // GET IP REQUEST IN USERS TABLE //
  //*******************************//

  GetUserIP() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getUserIp`),
      keys.auth.apiKey
    );
  },

  //**********************************//
  //  UPDATE REQUEST IN USERS TABLE   //
  //**********************************//
  Update(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/updateUser`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //**********************************//
  //  DELETE REQUEST IN USERS TABLE   //
  //**********************************//
  Delete(EmployeeCode) {
    return Promise.resolve(
      axios.delete(
        `${keys.auth.BaseUrl}/deleteUser/${EmployeeCode}`,
        keys.auth.apiKey
      )
    );
  },
};

export default httpRequest;
